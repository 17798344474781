import React from 'react'
import {graphql} from 'gatsby'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import Layout from './../components/layout'
import Container from './../components/container'
import PageHeading from './../components/page-heading'

const PrivacyPolicy = props => {
  const {data} = props
  const prismicData = {
    pageHeading: data.prismicPrivacyPolicyPage.data.page_heading.text,
    introText: data.prismicPrivacyPolicyPage.data.privacy_policy_intro_text.html,
    privacyPolicySections: data.prismicPrivacyPolicyPage.data.privacy_policy_sections,
  }

  // console.log('PrivacyPolicy prismicData:', prismicData)

  return (
    <Layout documentTitlePrefix="Privacy Policy" isIndexPage={false} pageName="privacy-policy" showScrollToTopBtn={true}>
      <PageHeading heading={prismicData.pageHeading} />
      <Container>
        <StyledPrivacyPolicy>
          <Fade>
            <div className="privacy-policy-intro-text" dangerouslySetInnerHTML={{__html: prismicData.introText}} />
          </Fade>

          {prismicData.privacyPolicySections &&
            prismicData.privacyPolicySections.map((item, index) => (
              <section className="privacy-policy-section" key={`privacy-policy-section-${index}`}>
                <Fade>
                  <h3 className="privacy-policy-section-heading">{item.section_heading.text}</h3>
                </Fade>
                <Fade>
                  <div
                    className="privacy-policy-section-body-text"
                    dangerouslySetInnerHTML={{__html: item.section_body_text.html}}
                  />
                </Fade>
              </section>
            ))}
        </StyledPrivacyPolicy>
      </Container>
    </Layout>
  )
}

const StyledPrivacyPolicy = styled.div`
  margin: 0 auto;
  max-width: 90rem;

  .privacy-policy-intro-text {
    margin-bottom: 4rem;
  }

  .privacy-policy-section {
    margin-bottom: 6rem;
  }

  .privacy-policy-section-heading {
    margin-bottom: 4rem;
  }

  .privacy-policy-section-body-text {
    padding-left: 2.5rem;
  }
`

export default PrivacyPolicy

export const pageQuery = graphql`
  {
    prismicPrivacyPolicyPage {
      data {
        page_heading {
          text
        }
        privacy_policy_intro_text {
          html
        }
        privacy_policy_sections {
          section_heading {
            text
          }
          section_body_text {
            html
          }
        }
      }
    }
  }
`
